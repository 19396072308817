import { ReceivedComponent } from './pages/received/received.component';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import { InfoComponent } from './pages/info/info.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/info',
    pathMatch: 'full'
  },
  {
    path: 'info',
    component: InfoComponent
  },
  {
    path:'confirm',
    component: ConfirmComponent
  },
  {
    path:'recieved',
    component: ReceivedComponent
  },
  {
    path: '**',
    redirectTo:'/info'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
