import { environment } from './../../../environments/environment';
import { Router, NavigationExtras } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { google } from "google-maps";

declare var google : google;


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  soldOut: boolean = false;
  // data:string = environment.item;
  errorMessage;
  GoogleAutocomplete: any;
  UserForm: FormGroup;
  shirtSizes = ['XXL', 'XL', 'L', 'M', 'S', 'XS']

  constructor(
    private router: Router
  ) {
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
    const navigation = this.router.getCurrentNavigation();
    let state = navigation.extras as {
      soldOut: boolean;
    }
    this.soldOut = state.soldOut;
   }

  ngOnInit() {
    //See if the promotion is still on going
    if(this.soldOut){
      // Returns user back to landing page if soldOut == true
      this.router.navigateByUrl('/landing');
    }else{
    // Set up the form group using Angular Reactive Forms
      this.UserForm = new FormGroup({
        'first_name': new FormControl(null, Validators.required),
        'last_name': new FormControl(null, Validators.required),
        'email': new FormControl(null, [Validators.required, Validators.email]),
        'address1': new FormControl(null, Validators.required),
        'address2': new FormControl(null),
        'city': new FormControl(null, Validators.required),
        'state': new FormControl(null, Validators.required),
        'zip': new FormControl(null, Validators.required),
        'company' : new FormControl(null, Validators.required),
        'title' : new FormControl(null, Validators.required),
        'tshirt-size': new FormControl('', Validators.required)
      })
    }
  }

  next(form: FormGroup) {
    // Send the user data with the route
    const navigationExtras: NavigationExtras = {
      // Set the state for the confirm page
      state: {
        first_name: form.get('first_name').value,
        last_name: form.get('last_name').value,
        email: form.get('email').value,
        address1: form.get('address1').value,
        address2: form.get('address2').value,
        city: form.get('city').value,
        state: form.get('state').value,
        zip: form.get('zip').value,
        company: form.get('company').value,
        title: form.get('title').value,
        tshirt_size: form.get('tshirt-size').value
      }
    }
    // Navigate to the confirm page with all the user data
    this.router.navigate(['confirm'], navigationExtras)
  }

   // Listens for user to select an autocompleted address...
  handleAddressChange(address: any) {
    //Split the formatted address to address, city, state, and zip
    let splitAddress = address.formatted_address.split(',');

    //Separate the zip and the state
    let cleanZip = splitAddress[2].trim()
    let clearnerZip = cleanZip.split(' ');

    //Split the formatted address into the address, city, state, and zip
    this.UserForm.patchValue({
      'address1': splitAddress[0],
      'city': splitAddress[1],
      'state': clearnerZip[0],
      'zip': clearnerZip[1]
    })
  }
}
