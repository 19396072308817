
<div class="main-container">
  <div class="container">
    <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple">
      <p class="spinner-text" style="font-size: 20px; color: white">Submitting...</p>
    </ngx-spinner>
    <div class="check-data">
      <div class="content">
       <div *ngIf="!errorMessage">
        <h1 id="check_instructions">Please make sure all your information is correct.</h1>
        <br>
        <p class="check-lines">{{data.user.first_name}}  {{data.user.last_name}}</p>
        <p class="check-lines">{{data.user.email_address}}</p>
        <p class="check-lines">{{data.user.address1}} {{data.user.address2}}</p>
        <p class="check-lines">{{data.user.city}} , {{data.user.state}} {{data.user.zip}}</p>
        <p class="check-lines">Company: {{data.user.company}}</p>
        <p class="check-lines">Title: {{data.user.title}}</p>
        <p class="check-lines">T-Shirt Size: {{data.product}}</p>
        <p class="check-lines">United States of America</p>
       </div>
        <div class="error-container" *ngIf="errorMessage">
          <h3>{{errorMessage}}</h3>
        </div>
      </div>
    </div>
    <div class="btn-container">
      <div class="button-container">
        <button routerLink="/info" class="pageBtn backBtn">
          BACK
        </button>
      </div>
      <div class="button-container">
        <button (click)="onSubmit()" *ngIf="!errorMessage" class="pageBtn nextBtn">
          SUBMIT
        </button>
      </div>
    </div>
  </div>
</div>
