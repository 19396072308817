<div class="main-container">
  <div class="container">
    <div id="check_data">
        <img class="bg" src="https://familylola-15775.kxcdn.com/assets/imgs/general-content-background-mobile.png"  alt="">
        <img class="desktop-img " src="https://familylola-15775.kxcdn.com/assets/imgs/general-content-background.png" alt="">
        <div class="content">
            <img class="toyota_logo"  src="https://familylola-15775.kxcdn.com//assets/imgs/toyota-logo.png" alt="">
            <br>
            <div *ngIf="!soldOut">
              <h1 class="check_lines">Click <a href="https://www.NYCWFF2020TOYOTASURVEY.com" style="color:#009B87 !important;">HERE</a> if you didn’t</h1>
              <h1 class="check_lines">complete the Toyota survey.</h1>
              <br>
              <h1 class="check_lines">Click the NEXT button </h1>
              <h1 class="check_lines">to continue to order</h1>
              <h1 class="check_lines">your complimentary {{data}}.</h1>
            </div>
            <div *ngIf="soldOut" id="check_data" style="z-index: 1;">
                <h1 class="check_lines">Uh Oh!</h1>
                <h1 class="check_lines">This promotion has ended!</h1>
                <br>
            </div>
        </div>
    </div>
    <br>
    <div *ngIf="!soldOut" class="button-container">
        <div class="end">
            <button class="pageBtn nextBtn" routerLink="/info"   >
                NEXT
            </button>
        </div>
    </div>
  </div>
</div>
