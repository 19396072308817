
<div class="main-container">
  <div class="container">
    <div class="check-data">
      <img class="bg" src="https://familylola-15775.kxcdn.com/assets/imgs/general-content-background-mobile.png"  alt="">
      <img class="desktop-img " src="https://familylola-15775.kxcdn.com/assets/imgs/general-content-background.png" alt="">      <div class="content">
        <img src="https://familylola-15775.kxcdn.com/assets/imgs/toyota-logo.png" alt="Toyota Logo">
        <br>
        <h1>Thank you for ordering your</h1>
        <h1>Shirt!</h1>
        <h1>Your order is being processed</h1>
        <h1>and will arrive within 4-6 weeks.</h1>
        <br>
      </div>
    </div>
    <div class="button-container">
      <button routerLink="/landing"  class="nextBtn">BACK</button>
    </div>
  </div>
</div>

