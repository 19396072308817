import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {
  // data: string = environment.item;

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
      // this.router.navigateByUrl('/info')
    }
  }
