import { UserInfo } from './../data-models/user-info.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  userInfo: UserInfo;
  link: string = 'https://spotcron.com/_endpoints/geekhive/geekhive2020?dowhat=submit';


  constructor(
    private http: HttpClient
  ) { }

  postUserData(data: any){
    return this.http.post(this.link, JSON.stringify(data));
  }


}
