<div class="main-container">
  <div class="container">
    <div id="box">
      <div class="content">
        <form id="browser_form" [formGroup]="UserForm" >
          <h1 id="name_instructions">Fill out your shipping information here to receive your shirt!</h1>
          <div class="input-container">
            <div class="input-row">
              <input type="text" formControlName="first_name" #first_name  placeholder="First Name" id="first-name">
              <input type="text" formControlName="last_name" #last_name  placeholder="Last Name" id="last-name">
            </div>
            <span *ngIf="!UserForm.get('first_name').valid && UserForm.get('first_name').touched">Please Enter a First Name</span>
            <span *ngIf="!UserForm.get('last_name').valid && UserForm.get('last_name').touched">Please Enter a Last Name</span>
            <div class="input-row">
              <input type="email" email formControlName="email" id="email" #email  placeholder="Company Email Address">
              <select formControlName="tshirt-size"  class="info-select">
                <option class="select-title" value="" hidden selected disabled>T-Shirt Size</option>
                <!-- <option value=""  hidden selected>T-Shirt Size</option> -->
                <option *ngFor="let shirt of shirtSizes" [value]="shirt">{{shirt}}</option>
              </select>
            </div>
          <div class="input-row company-container">
            <input type="text" formControlName="company" id="company" placeholder="Company Name">
            <input type="text" formControlName="title" id="company-title" placeholder="Company Title">
          </div>
          <span *ngIf="!UserForm.get('email').valid && UserForm.get('email').touched">Please Enter a Valid Email Address</span>
          <div class="input-row ship-row">
            <input placeholder="Address" formControlName="address1" ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>
            <input type="text" formControlName="address2" id="ship-2" placeholder="Apartment/Suite #">
          </div>
          <span *ngIf="!UserForm.get('address1').valid && UserForm.get('address1').touched">Please Enter a Valid Address</span>
          <div class="input-row location-con">
            <input type="text" formControlName="city" id="city" placeholder="City">
            <input type="text" formControlName="state" id="state"  placeholder="State/Region">
            <input type="text" formControlName="zip" id="zip" placeholder="Zip">
          </div>
          <div class="error-con">
            <span *ngIf="!UserForm.get('city').valid && UserForm.get('city').touched">Please Enter a Valid City</span>
            <span *ngIf="!UserForm.get('state').valid && UserForm.get('state').touched">Please Enter a Valid State</span>
            <span *ngIf="!UserForm.get('zip').valid && UserForm.get('zip').touched">Please Enter a Valid Zip</span>
          </div>
        </div>
      </form>
      <button
      [disabled]="!UserForm.valid"
      [ngStyle]="{'opacity': UserForm.invalid ? '0.5' : '1' }"
      type="submit"
      class="pageBtn nextBtn formSubBtn"
      (click)="next(UserForm)">
        NEXT
      </button>
    </div>
    </div>
    <br>
    <!-- <div class="button-container">
        <button
        [disabled]="!UserForm.valid"
        [ngStyle]="{'opacity': UserForm.invalid ? '0.5' : '1' }"
        type="submit"
        class="pageBtn nextBtn formSubBtn"
        (click)="next(UserForm)">
          NEXT
        </button>
    </div> -->
  </div>
</div>
