<div class="main-container">
  <div class="container">
    <div class="check-data">
        <br>
        <h2>Order Recieved!</h2>
        <br>
    </div>
    <!-- <div class="button-container"> -->
      <button (click)="onNext()"  class="nextBtn">BACK</button>
    <!-- </div> -->
  </div>
</div>
