import { UserInfo } from './../../data-models/user-info.model';
import { UserInfoService } from './../../services/user-info.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  isLoading = false;
  data = {
    user: {
      first_name: '',
      last_name: '',
      email_address: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      company: '',
      title: ''
    },
    product: ''
  }
  state: any;
  errorMessage: string;
  userInfo: UserInfo;

  constructor(
    private router: Router,
    private userService: UserInfoService,
    private spinner: NgxSpinnerService
    ) {
      const navigation = this.router.getCurrentNavigation();
      let state = navigation.extras.state as {
        first_name: string,
        last_name: string,
        email: string,
        address1: string,
        address2: string,
        city: string,
        state: string,
        zip: number,
        company: string,
        title: string,
        tshirt_size: string
      }
      this.state = state
    }

  ngOnInit(){
    if(!this.state){
      this.router.navigateByUrl('/info')
    }else{
      this.onChangeData()
    }
  }

  onChangeData(){
   this.userInfo = this.state
   this.data.user.first_name = this.userInfo.first_name
   this.data.user.last_name = this.userInfo.last_name
   this.data.user.email_address = this.userInfo.email
   this.data.user.address1 = this.userInfo.address1
   this.data.user.address2 = this.userInfo.address2
   this.data.user.city = this.userInfo.city
   this.data.user.state = this.userInfo.state
   this.data.user.zip = this.userInfo.zip.toString();
   this.data.user.company = this.userInfo.company,
   this.data.user.title = this.userInfo.title
   this.data.product = this.userInfo.tshirt_size
  }

  onSubmit(){
    this.isLoading = true;
    this.spinner.show();
    // Send the user data to API
    this.userService.postUserData(this.data)
    .toPromise()
    .then((res: any) => {
        if(res.success == true){
         this.isLoading = false;
         this.spinner.hide();
         this.router.navigateByUrl('/recieved')
        }else if(res.success == false){
          this.isLoading = false;
          this.spinner.hide();
          this.errorMessage = "An error occured, please try again!";
          console.log(res.error);
        }else{
         this.spinner.hide()
        }
    }).catch((error: Error) => {
      console.log(error)
      this.errorMessage = JSON.stringify(error)
      this.spinner.hide();
    })
  }
}
