import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  soldOut: boolean = false;
  // data: string = environment.item;

  constructor(
    private router: Router
    ) { }

  ngOnInit() {
  }

  onNext(){
    const navigationExtras: NavigationExtras = {
      state: {
        soldOut: this.soldOut
      }
    }
    this.router.navigate(['/info'], navigationExtras)
  }
}
